'use client'

import {Session} from 'next-auth'
import {SessionProvider} from 'next-auth/react'
import {SWRConfig} from 'swr'

import {PageContextProvider} from '@/contexts/page'
import PosthogWrapper from '@/lib/posthog-wrapper'

export function Providers({children, session}: {children: React.ReactNode; session: Session}) {
  return (
    <PosthogWrapper profileId={session?.profile}>
      <SWRConfig value={{provider: () => new Map(), revalidateOnFocus: false}}>
        <SessionProvider session={session} refetchInterval={60 * 60} refetchOnWindowFocus={false}>
          <PageContextProvider>{children}</PageContextProvider>
        </SessionProvider>
      </SWRConfig>
    </PosthogWrapper>
  )
}
